import React from "react"
import {
  FullWidthColumn,
  FlexWrapMobile,
  InlineLink,
  Heading2,
} from "../components/content-elements"
import Layout from "../components/layout"

const NotFoundPage = ({ location }) => {

  return (
    <Layout location={location}>

      <FlexWrapMobile>
        <FullWidthColumn>
          <section>
            <Heading2>404: Not Found</Heading2>
            <p>You just hit a URL that doesn&#39;t exist... sorry. Please <InlineLink to={`/`}>go back to the
              homepage.</InlineLink></p>
          </section>
        </FullWidthColumn>
      </FlexWrapMobile>
    </Layout>
  )
}

export default NotFoundPage